export const emptyPlugHashes = new Set<number>([
  235531041, // Activity Mod Socket (Activity Ghost Mod, enhancements.ghosts_activity)
  3545404847, // Activity Mod Socket (Activity Ghost Mod, enhancements.ghosts_activity_fake)
  1390587439, // Default Effect (ship.spawnfx)
  1608119540, // Default Emblem (Emblem, emblem.variant)
  702981643, // Default Ornament (Restore Defaults, armor_skins_empty)
  1959648454, // Default Ornament (exotic_all_skins)
  2931483505, // Default Ornament (exotic_all_skins)
  2325217837, // Default Shader (Restore Defaults, shader)
  4248210736, // Default Shader (Restore Defaults, shader)
  2794014115, // Default Weapon Effects (v420.plugs.weapons.masterworks.toggle.vfx)
  1675508353, // Economic Mod Socket (Economic Ghost Mod, enhancements.ghosts_economic)
  791435474, // Empty Activity Mod Socket (Deprecated Armor Mod, enhancements.activity)
  3074755706, // Empty Arrows Socket (crafting.recipes.empty_socket)
  2802541735, // Empty Aspect Socket (hunter.arc.aspects)
  518663192, // Empty Aspect Socket (hunter.prism.aspects)
  1715180370, // Empty Aspect Socket (hunter.shared.aspects)
  3875863236, // Empty Aspect Socket (hunter.solar.aspects)
  4037640975, // Empty Aspect Socket (hunter.strand.aspects)
  2801436041, // Empty Aspect Socket (hunter.void.aspects)
  2789698445, // Empty Aspect Socket (titan.arc.aspects)
  3635963100, // Empty Aspect Socket (titan.prism.aspects)
  321296654, // Empty Aspect Socket (titan.shared.aspects)
  3416473448, // Empty Aspect Socket (titan.solar.aspects)
  3207138885, // Empty Aspect Socket (titan.strand.aspects)
  662916127, // Empty Aspect Socket (titan.void.aspects)
  3472368310, // Empty Aspect Socket (warlock.arc.aspects)
  1080004479, // Empty Aspect Socket (warlock.prism.aspects)
  3819991001, // Empty Aspect Socket (warlock.shared.aspects)
  2352766955, // Empty Aspect Socket (warlock.solar.aspects)
  2164407902, // Empty Aspect Socket (warlock.strand.aspects)
  3834374608, // Empty Aspect Socket (warlock.void.aspects)
  1007199041, // Empty Barrels Socket (crafting.recipes.empty_socket)
  1527687869, // Empty Batteries Socket (crafting.recipes.empty_socket)
  2836298415, // Empty Blades Socket (crafting.recipes.empty_socket)
  3471922734, // Empty Bowstrings Socket (crafting.recipes.empty_socket)
  1498917124, // Empty Catalyst Socket (v400.empty.exotic.masterwork)
  1649663920, // Empty Catalyst Socket (v400.empty.exotic.masterwork)
  1961918267, // Empty Deepsight Socket (crafting.plugs.weapons.mods.extractors)
  253922071, // Empty Enhancement Socket (crafting.plugs.weapons.mods.enhancers)
  1826298670, // Empty Fragment Socket (shared.arc.fragments)
  3363787531, // Empty Fragment Socket (shared.arc.fragments)
  3251563851, // Empty Fragment Socket (shared.fragments)
  2808665197, // Empty Fragment Socket (shared.prism.fragments)
  3720092164, // Empty Fragment Socket (shared.prism.fragments)
  424005861, // Empty Fragment Socket (shared.solar.fragments)
  4205702044, // Empty Fragment Socket (shared.solar.fragments)
  330751742, // Empty Fragment Socket (shared.stasis.trinkets)
  1618645595, // Empty Fragment Socket (shared.strand.fragments)
  2111549310, // Empty Fragment Socket (shared.strand.fragments)
  770211541, // Empty Fragment Socket (shared.void.fragments)
  1372656116, // Empty Fragment Socket (shared.void.fragments)
  1372656117, // Empty Fragment Socket (shared.void.fragments)
  1219897208, // Empty Frames Socket (crafting.recipes.empty_socket)
  366474809, // Empty Grips Socket (crafting.recipes.empty_socket)
  1779961758, // Empty Guards Socket (crafting.recipes.empty_socket)
  1232390730, // Empty Hafts Socket (crafting.recipes.empty_socket)
  3057124503, // Empty Magazines Socket (crafting.recipes.empty_socket)
  3803329707, // Empty Magazines Socket (crafting.recipes.empty_socket)
  2909846572, // Empty Memento Socket (crafting.recipes.empty_socket)
  481675395, // Empty Mod Socket (General Armor Mod, deprecated)
  573150099, // Empty Mod Socket (Leg Armor Mod, deprecated)
  807186981, // Empty Mod Socket (Helmet Armor Mod, deprecated)
  1137289077, // Empty Mod Socket (Class Item Armor Mod, deprecated)
  1659393211, // Empty Mod Socket (Chest Armor Mod, deprecated)
  1844045567, // Empty Mod Socket (Arms Armor Mod, deprecated)
  4173924323, // Empty Mod Socket (Artifice Armor Mod, enhancements.artifice)
  4055462131, // Empty Mod Socket (Deep Stone Crypt Raid Mod, enhancements.raid_descent)
  706611068, // Empty Mod Socket (Garden of Salvation Raid Mod, enhancements.raid_garden)
  3738398030, // Empty Mod Socket (Vault of Glass Armor Mod, enhancements.raid_v520)
  2447143568, // Empty Mod Socket (Vow of the Disciple Raid Mod, enhancements.raid_v600)
  1728096240, // Empty Mod Socket (King's Fall Mod, enhancements.raid_v620)
  4144354978, // Empty Mod Socket (Root of Nightmares Armor Mod, enhancements.raid_v700)
  717667840, // Empty Mod Socket (Crota's End Mod, enhancements.raid_v720)
  4059283783, // Empty Mod Socket (Salvation's Edge Armor Mod, enhancements.raid_v800)
  720857, // Empty Mod Socket (Legacy Armor Mod, enhancements.season_forge)
  1180997867, // Empty Mod Socket (Nightmare Mod, enhancements.season_maverick)
  2620967748, // Empty Mod Socket (Legacy Armor Mod, enhancements.season_maverick)
  4106547009, // Empty Mod Socket (Legacy Armor Mod, enhancements.season_opulence)
  1679876242, // Empty Mod Socket (Last Wish Raid Mod, enhancements.season_outlaw)
  3625698764, // Empty Mod Socket (Legacy Armor Mod, enhancements.season_outlaw)
  1182150429, // Empty Mod Socket (Armor Mod, enhancements.universal)
  1835369552, // Empty Mod Socket (enhancements.universal)
  2600899007, // Empty Mod Socket (Armor Mod, enhancements.universal)
  3851138800, // Empty Mod Socket (Armor Mod, enhancements.universal)
  1285086138, // Empty Mod Socket (Arms Artifact Mod, enhancements.v2_arms)
  3820147479, // Empty Mod Socket (Arms Armor Mod, enhancements.v2_arms)
  1803434835, // Empty Mod Socket (Chest Armor Mod, enhancements.v2_chest)
  3965359154, // Empty Mod Socket (Chest Artifact Mod, enhancements.v2_chest)
  3200810407, // Empty Mod Socket (Class Item Armor Mod, enhancements.v2_class_item)
  4059708161, // Empty Mod Socket (Class Item Artifact Mod, enhancements.v2_class_item)
  1980618587, // Empty Mod Socket (General Armor Mod, enhancements.v2_general)
  787139317, // Empty Mod Socket (Helmet Artifact Mod, enhancements.v2_head)
  1078080765, // Empty Mod Socket (Helmet Armor Mod, enhancements.v2_head)
  79843948, // Empty Mod Socket (Leg Artifact Mod, enhancements.v2_legs)
  2269836811, // Empty Mod Socket (Leg Armor Mod, enhancements.v2_legs)
  144338558, // Empty Mod Socket (Weapon Mod, v400.weapon.mod_empty)
  2323986101, // Empty Mod Socket (Weapon Mod, v400.weapon.mod_empty)
  4024425661, // Empty Mod Socket (Armor Mod, v404.armor.fotl.masks.abyss.perks)
  51925409, // Empty Scopes Socket (crafting.recipes.empty_socket)
  1134447515, // Empty Stocks Socket (crafting.recipes.empty_socket)
  469511105, // Empty Traits Socket (crafting.recipes.empty_socket)
  2503665585, // Empty Traits Socket (crafting.recipes.empty_socket)
  819232495, // Empty Tubes Socket (crafting.recipes.empty_socket)
  4043342755, // Empty Weapon Level Boost Socket (crafting.plugs.weapons.mods.transfusers.level)
  4216349042, // Experience Mod Socket (Experience Ghost Mod, enhancements.ghosts_experience)
  2085536058, // Flickering Blessing Mod Socket (Flickering Blessing Destination Mod, schism_boons.destination_mods.efficiency)
  1656746282, // Locked Artifice Socket (Artifice Armor Mod, enhancements.artifice.exotic)
  3725942064, // Pale Blessing Mod Socket (Pale Blessing Destination Mod, schism_boons.destination_mods.playstyle)
  1692473496, // Protocol Socket (v700.weapons.mods.mission_avalon)
  760030801, // Tracking Mod Socket (Tracking Ghost Mod, enhancements.ghosts_tracking)
]);
